import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        LeftList: JSON.parse(sessionStorage.getItem('auth' || '[]')),
        UserName: sessionStorage.getItem('uname')
    },
    mutations: {
        setLeftList (state, data) {
            state.LeftList = data
            sessionStorage.setItem('auth', JSON.stringify(data))
        },
        setUserName (state, data) {
            state.UserName = data
            sessionStorage.setItem('uname', data)
        }
    },
    actions: {
    },
    modules: {
    }
})
