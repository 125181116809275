import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

Vue.use(VueRouter)

const authRule = { path: 'auth', component: () => import('../views/author/auth/Auth.vue') } // 权限
const roleRule = { path: 'authuser', component: () => import('../views/author/authuser/Authuser.vue') } // 角色管理
const groupRule = { path: 'authgroup', component: () => import('../views/author/authgroup/Authgroup.vue') } // 权限组
const censusRule = { path: 'census', alias: '/', component: () => import('../views/census/Census.vue') } // 统计
const customRule = { path: 'custom', component: () => import('../views/custom/Custom.vue') } // 客户管理
const salespRule = { path: 'sales', component: () => import('../views/sales/Sales.vue') } // 业务人员管理
const partnerRule = { path: 'partner', component: () => import('../views/partner/Partner.vue') } // 合作方

const ruleMapping = {
    '/auth': authRule,
    '/authuser': roleRule,
    '/authgroup': groupRule,
    '/census': censusRule,
    '/custom': customRule,
    '/sales': salespRule,
    '/partner': partnerRule,
}

const routes = [
    {//登录
        path: '/login',
        component: () => import('../views/login/Login.vue')
    },
    {//首页
        path: '/',
        component: () => import('../views/index/Index.vue'),
        children: [
            {//权限  添加编辑
                path: '/authadd',
                component: () => import('../views/author/auth/authAdd.vue')
            },
            {//权限组  添加编辑
                path: '/groupadd',
                component: () => import('../views/author/authgroup/groupadd.vue')
            },
            {//权限用户    添加编辑
                path: '/roleadd',
                component: () => import('../views/author/authuser/roleAdd.vue')
            },
            {//渠道管理   添加编辑
                path: '/partadd',
                component: () => import('../views/partner/partAdd.vue')
            },
            {//业务人员管理   业务公司详情
                path: '/detail',
                component: () => import('../views/sales/detail.vue')
            },
            {//业务人员管理   添加编辑
                path: '/detailadd',
                component: () => import('../views/sales/detailAdd.vue')
            },
            {//业务人员管理   导入
                path: '/salesload',
                component: () => import('../views/sales/salesLoad.vue')
            },
            {//客户人员管理   添加编辑
                path: '/customadd',
                component: () => import('../views/custom/customerAdd.vue')
            },
            {//客户人员管理   导入
                path: '/customload',
                component: () => import('../views/custom/customerLoad.vue')
            },
        ]
    },
]

const router = new VueRouter({
    routes
})

// 全局前置守卫
router.beforeEach((to, from, next) => {
    if (to.path == '/login') {
        next()
    } else {
        let token = sessionStorage.getItem('Token')
        if (!token) return next({ path: '/login' })
        next()
    }
})

export function initRouter () {
    const currentRoutes = router.options.routes;
    const LeftList = store.state.LeftList;
    let arr = [];
    if (LeftList != null) {
        LeftList.forEach(item => {
            if (item.children.length != 0) {
                item.children.forEach(item => {
                    let temp = ruleMapping[item.auth_route]
                    currentRoutes[1].children.push(temp);
                    temp.meta = item.page_type.split(',');
                    arr.push(temp.meta)
                })
            }
        })
    }
    router.addRoutes(currentRoutes)
}

export default router
