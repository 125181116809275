import Vue from 'vue'
import router from '@/router'

Vue.directive('permission', {
    inserted (el, binding) {
        const action = binding.value.action;
        //console.log(router.currentRoute)
        // const pageType = localStorage.getItem('page_type');
        // console.log(pageType);
        if (router.currentRoute.meta.indexOf(action) == -1) {
            el.parentNode && el.parentNode.removeChild(el)
        }
    }
})